import Vue from 'vue'
import Service from "../Service";

const resource = "hmnBiometricEquipment/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID }
        });
    },
    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },   
    //listado de los biometricos
    list() { return Vue.prototype.$http.defaults.baseURL + "/" + resource + "list" },
    //---------------------------

    //obtener la conexion del biometrico
    isConnected(requestID) {
        return Service.post(resource + "isConnected", {}, {
            params: { requestID: requestID },
        });
    }
};